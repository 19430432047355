import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import "./font-awesome"
import urlSlug from "url-slug"

import logoFooter01 from "../images/ihs.png"
import logoFooter02 from "../images/ucc_logo.png"

/* import { faNewspaper } from "@fortawesome/free-regular-svg-icons" */

const Footer = ({ data, dataCenters, icda }) => (
  <footer>
    {/* Whats App */}
    <div className="btn-whatsapp" key={`link-whatsapp`}>
      <a href={"https://api.whatsapp.com/send/?phone=5493512012536&text&type=phone_number&app_absent=0"} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} className="icon-whatsapp"/>
      </a>
    </div>
       
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css"
    ></link>
    <div className="py-4 bg-black text-white">
      <div className="container p text-light">
        <div className="row">
          <div className="col-12 my-3">
            <div className="d-flex flex-wrap align-items-center">
              <div className="flex-grow-1">
                <p className="mb-2">ICDA, ESCUELA DE NEGOCIOS</p>
                <h1 className="text-white mb-3 mr-2">
                    Líderes responsables
                  <br />
                    con pensamiento global
                </h1>
              </div>
              <div className="d-flex mb-3 justify-content-around">
                <a
                  href="https://www.facebook.com/icda.ucc"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-social my-1 mx-2 rounded-circle btn-social-icon btn-facebook"
                >
                  <FontAwesomeIcon icon={["fab", "facebook-f"]} size="lg" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCzr4HB0D2tY5CGjFeV_i-FA"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-social my-1 mx-2 rounded-circle btn-social-icon btn-youtube"
                >
                  <FontAwesomeIcon icon={["fab", "youtube"]} size="lg" />
                </a>
                <a
                  href="https://linkedin.com/company/icda-ucc"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-social my-1 mx-2 rounded-circle btn-social-icon btn-linkedin"
                >
                  <FontAwesomeIcon icon={["fab", "linkedin-in"]} size="lg" />
                </a>
                <a
                  href="https://twitter.com/icda_ucc"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-social my-1 mx-2 rounded-circle btn-social-icon btn-twitter"
                >
                  <FontAwesomeIcon icon={["fab", "twitter"]} size="lg" />
                </a>
                <a
                  href="https://www.instagram.com/icda.ucc/?hl=es"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-social my-1 mx-2  rounded-circle btn-social-icon btn-instagram"
                >
                  <FontAwesomeIcon icon={["fab", "instagram"]} size="lg" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 col-lg-3 my-2">
            <div className="d-flex flex-column">
              <h5>Contacto</h5>
              <div className="mb-3 d-flex flex-column">
                <div className="d-flex text-secondary mb-2">
                  <h6 className="mb-0">
                    <i className="fa fa-angle-right font-weight-bold mr-1" />
                  </h6>
                  <h6 className="text-secondary font-weight-bold mb-0">
                    General:
                  </h6>
                </div>
                <a
                  href="mailto:info.icda@ucc.edu.ar"
                  className="text-secondary ml-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  info.icda@ucc.edu.ar
                </a>
                <a
                  href="tel:+5493512012536"
                  className="text-secondary ml-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  +54 9 351 2012536
                </a>
                <a
                  href="tel:+5493512385696"
                  className="text-secondary ml-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  +54 9 351 238569
                </a>
              </div>
              {/* <div className="mb-3 d-flex flex-column">
                <div className="d-flex text-secondary mb-2">
                  <h6 className="mb-0">
                    <i className="fa fa-angle-right font-weight-bold mr-1" />
                  </h6>
                  <h6 className="text-secondary font-weight-bold mb-0">
                    Posgrado:
                  </h6>
                </div>
                <a
                  href="mailto:posgrados.icda@ucc.edu.ar"
                  className="text-secondary ml-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  posgrados.icda@ucc.edu.ar
                </a>
              </div>
              <div className="mb-3 d-flex flex-column">
                <div className="d-flex text-secondary mb-2">
                  <h6 className="mb-0">
                    <i className="fa fa-angle-right font-weight-bold mr-1" />
                  </h6>
                  <h6 className="text-secondary font-weight-bold mb-0">
                    Pregrado y formación continua:
                  </h6>
                </div>
                <a
                  href="mailto:academica.icda@ucc.edu.ar"
                  className="text-secondary ml-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  academica.icda@ucc.edu.ar
                </a>
              </div> */}
              <div className="mb-3 d-flex flex-column">
                <div className="d-flex text-secondary mb-2">
                  <h6 className="mb-0">
                    <i className="fa fa-angle-right font-weight-bold mr-1" />
                  </h6>
                  <h6 className="text-secondary font-weight-bold mb-0">
                    Formación In company:
                  </h6>
                </div>
                <a
                  href="mailto:incompany.icda@ucc.edu.ar"
                  className="text-secondary ml-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  incompany.icda@ucc.edu.ar
                </a>
                <a
                  href="tel:+5493512012536"
                  className="text-secondary ml-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  +54 9 351 3118735
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 my-2">
            <div className="d-flex flex-column">
              <h5>Posgrados</h5>
              {icda ? (
                <Link to={"/carreras/mba"} className="text-secondary">
                  <p className="mb-0 py-2 text-uppercase text-secondary">
                    MAESTRÍA EN DIRECCIÓN DE EMPRESAS
                  </p>
                </Link>
              ) : null}
              {data.map((cat, i) => (
                <CustomListFooter
                  category={cat}
                  title={cat.fieldvalue}
                  key={`customListFooter-${i}`}
                  className="text-uppercase"
                />
              ))}
            </div>
          </div>
          <div className="col-12 col-lg-3 my-2">
            <h5>Formación ejecutiva</h5>
            <Link to="/formacion-corporativa" className="text-secondary">
              <p className="mb-0 py-2 text-uppercase text-secondary">
                Formación corporativa
              </p>
            </Link>
            <Link to={"/formacion-continua"} className="text-secondary">
              <p className="mb-0 py-2 text-uppercase text-secondary">
                Formación continua
              </p>
            </Link>
          </div>
          {dataCenters.edges.length > 0 && (
            <div className="col-12 col-md-3 my-2">
              <h5>Centros</h5>
              {dataCenters.edges.map(({ node }) => (
                <Link
                  to={`/centros/${node.slug}`}
                  key={`/centros/${node.slug}`}
                  className="text-secondary"
                >
                  <p className="mb-0 py-2 text-uppercase text-secondary">
                    {node.name}
                  </p>
                </Link>
              ))}
            </div>
          )}
        </div>
        <div className="row mt-2">
          <div className="col">
            <div className="d-flex align-items-center justify-content-around flex-wrap">
              <img
                src={logoFooter01}
                className="img-fluid m-2"
                alt="Jesuitas"
              />
              <img
                src={logoFooter02}
                className="img-fluid m-2"
                alt="Universidad Catolica de Córdoba"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer

const CustomListFooter = ({ category, title }) => {
  if (category.nodes.length > 0) {
    if (category.nodes.length >= 2) {
      return (
        <div className="my-2">
          <h6>{category.fieldValue}</h6>
          {category.nodes?.length
            ? category.nodes.map((document, index) => (
                <Link
                  to={`/carreras/${urlSlug(document.slug)}`}
                  key={`carreras/${urlSlug(document.slug)}`}
                  className="text-secondary"
                >
                  <p className="mb-0 py-2 text-uppercase text-secondary">
                    {document.name}
                  </p>
                </Link>
              ))
            : null}
        </div>
      )
    } else {
      return (
        <div className="my-2">
          <h6>{category.fieldValue}</h6>
          <Link
            to={`/carreras/${urlSlug(category.nodes[0].slug)}`}
            className="text-secondary"
          >
            <p className="mb-0 py-2 text-uppercase text-secondary">
              {category.nodes[0].name}
            </p>
          </Link>
        </div>
      )
    }
  } else {
    return null
  }
}
