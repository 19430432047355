import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logoICDA from "../images/logo_icda.svg"
import logoAlumni from "../images/logo_alumni.png"
import urlSlug from "url-slug"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Navbar, Nav, NavDropdown, DropdownButton } from "react-bootstrap"

const Header = ({ data, dataCenters, icda, isAlumni = false } ) => {

  const logo = isAlumni !== false ? logoAlumni : logoICDA

  return (
    <header className="bg-main fixed-top">
      <Navbar
        bg="light"
        expand="lg"
        className="shadow flex-lg-column pt-4 pb-3 navbar mr-auto"
      >
        <div className="w-100 d-flex flex-column flex-lg-row">
          <div className="flex-grow-1 d-flex flex-row align-items-center flex-wrap">
            <Navbar.Brand>
              <Link to="/">
                <img
                  src={logo}
                  alt="logo"
                  className="img-fluid img-small mr-2 logo"
                />
              </Link>
            </Navbar.Brand>
          </div>
          <div className="d-flex d-flex align-items-center">
            <div className="ml-auto social order-2 d-flex">
              <a
                href="https://www.facebook.com/icda.ucc"
                target="_blank"
                rel="noreferrer"
                className="btn btn-social m-1 rounded-circle btn-social-icon"
              >
                <FontAwesomeIcon icon={["fab", "facebook-f"]} />
              </a>
              <a
                href="https://www.youtube.com/channel/UCzr4HB0D2tY5CGjFeV_i-FA"
                target="_blank"
                rel="noreferrer"
                className="btn btn-social m-1 rounded-circle btn-social-icon"
              >
                <FontAwesomeIcon icon={["fab", "youtube"]} />
              </a>
              <a
                href="https://linkedin.com/company/icda-ucc"
                target="_blank"
                rel="noreferrer"
                className="btn btn-social m-1 rounded-circle btn-social-icon"
              >
                <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
              </a>
              <a
                href="https://twitter.com/icda_ucc"
                target="_blank"
                rel="noreferrer"
                className="btn btn-social m-1 rounded-circle btn-social-icon"
              >
                <FontAwesomeIcon icon={["fab", "twitter"]} />
              </a>
              <a
                href="https://www.instagram.com/icda.ucc/?hl=es"
                target="_blank"
                rel="noreferrer"
                className="btn btn-social m-1 rounded-circle btn-social-icon"
              >
                <FontAwesomeIcon icon={["fab", "instagram"]} />
              </a>
            </div>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="border-0 pl-0 order-1"
            />
          </div>
        </div>

        <Navbar.Collapse id="basic-navbar-nav" className="w-100 mt-lg-4">
          <Nav className="mr-auto menu font-weight-bold">
            {/* {icda ? (
              <Link to="/carreras/mba" className="nav-link ">
                MBA
              </Link>
            ) : null} */}
            <NavDropdown
              title="POSGRADOS"
              className="text-uppercase"
            >
              {data.map((cat, index) =>
                cat.fieldValue === "Maestrías" ? (
                  <CustomDropdown
                    category={cat}
                    title={cat.fieldValue.toUpperCase()}
                    key={`customdropdown-${index}`}
                    className="text-uppercase"
                  />
                ) : null
              )}
              {data.map((cat, index) =>
                cat.fieldValue != "Maestrías" ? (
                  <CustomDropdown
                    category={cat}
                    title={cat.fieldValue.toUpperCase()}
                    key={`customdropdown-${index}`}
                    className="text-uppercase"
                  />
                ) : null
              )}
            </NavDropdown>

            {/* <CustomDropdownFormacionAcademica /> */}

            <Link to="/formacion-corporativa" className="nav-link text-uppercase">
                Formación corporativa
            </Link>
            <Link to={"/formacion-continua"} className="nav-link text-uppercase">
                Formación continua
            </Link>
      
            {dataCenters.edges.length > 0 && (
              <NavDropdown
                title="CENTROS E INVESTIGACIÓN"
                id="centros"
                className="text-uppercase"
              >
                {dataCenters.edges.map(({ node }) => (
                  <Link
                    to={`/centros/${node.slug}`}
                    key={`/centros/${node.slug}`}
                    className="dropdown-item"
                  >
                    <h6 className="text-wrap-lg text-uppercase mb-0 py-2">
                      <small>{node.name}</small>
                    </h6>
                  </Link>
                ))}
              </NavDropdown>
            )}

            <NavDropdown
              title="INTERNACIONALIZACIÓN"
              id="centros"
              className="text-uppercase"
            >
              <Link to="/internacionalizacion" key="" className="dropdown-item">
                <h6 className="text-wrap-lg text-uppercase mb-0 py-2">
                  <small>Internacionalización (Noticias)</small>
                </h6>
              </Link>
              <Link to="/doble-titulacion" key="" className="dropdown-item">
                <h6 className="text-wrap-lg text-uppercase mb-0 py-2">
                  <small>Doble titulación</small>
                </h6>
              </Link>
              <Link to="/programas-internacionales" key="" className="dropdown-item">
                <h6 className="text-wrap-lg text-uppercase mb-0 py-2">
                  <small>
                    Programas Internacionales
                  </small>
                </h6>
              </Link>
            </NavDropdown>
          </Nav>
          <Nav className="justify-content-end menu font-weight-bold">
            {/* <Link to="/institucional" className="nav-link">INSTITUCIONAL</Link> */}
            <CustomDropdownInstitucional />
            <CustomDropdownAlumni />
            <CustomDropdownCampusVirtual />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}
// const CustomDropdownFormacionAcademica = () => {
//   return (
//     <NavDropdown
//       title={"Formacion ejecutiva"}
//       id={`Formacion ejecutiva`}
//       className="text-uppercase"
//     >
//       <Link to="/formacion-corporativa" className="dropdown-item">
//         <h6 className="text-wrap-lg mb-0 py-2">
//           <small>Formación corporativa</small>
//         </h6>
//       </Link>
//       <Link to={"/formacion-continua"} className="dropdown-item">
//         <h6 className="text-wrap-lg mb-0 py-2">
//           <small>Formación continua</small>
//         </h6>
//       </Link>
//     </NavDropdown>
//   )
// }

const CustomDropdownCampusVirtual = () => {
  return (
    <NavDropdown
      title={"Campus virtual"}
      id={`campusvirtual-dropdown`}
      className="text-uppercase"
    >
      <Link
        to="https://age.ucc.edu.ar/web/siuccweb_php/index.php?pc=AG_WEB3_SSL"
        className="dropdown-item"
      >
        <h6 className="text-wrap-lg mb-0 py-2">
          <small>AUTOGESTIÓN</small>
        </h6>
      </Link>
      <Link
        to="https://campusvirtual.ucc.edu.ar/login/index.php"
        className="dropdown-item"
      >
        <h6 className="text-wrap-lg mb-0 py-2">
          <small>Moodle</small>
        </h6>
      </Link>
      <Link
        to="https://campus.icda.ucc.edu.ar/acceso.cgi"
        className="dropdown-item"
      >
        <h6 className="text-wrap-lg mb-0 py-2">
          <small>Educativa</small>
        </h6>
      </Link>
    </NavDropdown>
  )
}

const CustomDropdownAlumni = () => {
  return (
    <NavDropdown
      title={"ALUMNI"}
      id={`campusvirtual-dropdown`}
      className="text-uppercase"
    >
      <Link to="/alumni" className="dropdown-item">
        <h6 className="text-wrap-lg mb-0 py-2">
          <small>Alumni UCC | ICDA</small>
        </h6>
      </Link>
      <Link
        to="https://graduados.ucc.edu.ar/index.html"
        className="dropdown-item"
      >
        <h6 className="text-wrap-lg mb-0 py-2">
          <small>Actualiza tus datos</small>
        </h6>
      </Link>
    </NavDropdown>
  )
}

const CustomDropdownInstitucional = () => {
  return (
    <NavDropdown
      title={"Institucional"}
      id={`institucional-dropdown`}
      className="text-uppercase"
    >
      <Link to="/institucional" className="dropdown-item">
        <h6 className="text-wrap-lg mb-0 py-2">
          <small>Nuestra historia</small>
        </h6>
      </Link>
      <Link to={"/equipo-de-liderazgo"} className="dropdown-item">
        <h6 className="text-wrap-lg mb-0 py-2">
          <small>Equipo de liderazgo</small>
        </h6>
      </Link>
      <Link to={'/preguntas-frecuentes'} className="dropdown-item">
        <h6 className="text-wrap-lg mb-0 py-2">
          <small>Preguntas Frecuentes</small>
        </h6>
      </Link>
    </NavDropdown>
  )
}

const CustomDropdown = ({ category, title }) => {
  if (category.nodes.length > 0) {
    if (category.nodes.length >= 2) {
      return (
        <DropdownButton
          title={title}
          id={`dropdown-${title}`}
          key={`dropdown-${title}`}
          className="text-uppercase dropdown-item"
          variant="transparent border-focus"
          drop="right"
        >
          {category.nodes?.length
            ? category.nodes.map((document, index) => (
                <Link
                  to={`/carreras/${urlSlug(document.slug)}`}
                  key={`/carreras/${urlSlug(document.slug)}`}
                  className="dropdown-item"
                >
                  <h6 className="text-wrap-lg mb-0 py-2 ">
                    <small>{document.name}</small>
                  </h6>
                </Link>
              ))
            : null
          }
          {title == "ESPECIALIZACIONES" && (
            <Link
              to="https://www.ucc.edu.ar/carreras/direccion-gestion-organismos-judiciales-juridicos"
              key="external-career-link"
              className="dropdown-item"
            >
              <h6 className="text-wrap-lg mb-0 py-2 ">
                <small>ESPECIALIZACIÓN EN DIRECCIÓN Y GESTIÓN DE ORGANISMOS JUDICIALES Y JURÍDICOS</small>
              </h6>
            </Link>
          )}
          {/* <Link
            to={`/carreras/${urlSlug(category.fieldValue)}`}
            key={`/carreras/${urlSlug(category.fieldValue)}`}
            className="dropdown-item"
          >
            <h6 className="text-wrap-lg mb-0 py-2">
              <small>Ver más</small>
            </h6>
          </Link> */}
        </DropdownButton>
      )
    } else {
      return (
        <DropdownButton
          title={title}
          id={`dropdown-${category.fieldValue}`}
          key={`dropdown-${category.fieldValue}`}
          className="text-uppercase dropdown-item dropdown-auto"
          drop="right"
          variant="transparent border-focus"
        >
          <Link
            to={`/carreras/${urlSlug(category.nodes[0].slug)}`}
            key={`/carreras/${urlSlug(category.nodes[0].slug)}`}
            className="dropdown-item"
          >
            <h6 className="text-wrap-lg mb-0 py-2">
              <small>{category.nodes[0].name}</small>
            </h6>
          </Link>
        </DropdownButton>
      )
    }
  } else {
    return null
  }
}
Header.propTypes = {
  siteTitle: PropTypes.string,
  data: PropTypes.array,
}
Header.defaultProps = {
  siteTitle: ``,
}
export default Header
