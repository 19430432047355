/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

import "bootstrap/dist/css/bootstrap.min.css"

import "../assets/css/theme.scss"
import { Spinner } from "react-bootstrap"

const Layout = ({ children, isAlumni }) => {
  const [hijo] = useState(children)
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }

      allStrapiAcademicUnits {
        edges {
          node {
            name
            id
          }
        }
      }
      icda: strapiIcdaCareers(siuccCode: { eq: "1104" }) {
        name
      }
      allStrapiCareerCategories(
        filter: { careers: { elemMatch: { academic_unit: { eq: 1 } } } }
      ) {
        edges {
          node {
            id
            name
            careers {
              id
              slug
              name
            }
          }
        }
      }
      allStrapiIcdaCareers(
        filter: {
          academic_unit: { name: { eq: "ICDA" } }
        }
      ) {
        group(field: career_category___name) {
          fieldValue
          nodes {
            id
            slug
            name
          }
        }
      }
      allStrapiCenter(filter: { academic_unit: { name: { eq: "ICDA" } } }) {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="min-vh-100 d-flex flex-column">
        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          data={data.allStrapiIcdaCareers.group}
          dataCenters={data.allStrapiCenter}
          icda={data.icda ? true : false}
          isAlumni={isAlumni}
        />
        {hijo ? (
          <main className="flex-grow-1 fade-in">{hijo}</main>
        ) : (
          <div className="flex-grow-1 d-flex flex-column align-items-center p-5 my-4 fade-in">
            <Spinner animation="border" role="status" variant="primary">
              <span className="sr-only">Cargando...</span>
            </Spinner>
          </div>
        )}
        <Footer
          data={data.allStrapiIcdaCareers.group}
          dataCenters={data.allStrapiCenter}
          icda={data.icda ? true : false}
        />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
